<template>
    <div class="panel">
        <div class="panel__actions desktop" >
            <div id="panel-btn-teleport" class="tw-mr-2"></div>
            <Button v-show="!hideButtons" class="panel__btn" :data="item" v-for="item in buttonsFiltered" :key="`button-${item.event}`" @click="$emit(`${item.event}`)" />
        </div>

        <div class="panel__actions mobile">
            <template v-if="buttonsFiltered?.slice(1).length">
                <div class="btn-extra-wrapper">
                    <!-- <Button :data="{title: '..s'}" @click="showExtraMobileButtons = !showExtraMobileButtons" class="panel__btn">...</Button> -->
                    <button @click="showExtraMobileButtons = !showExtraMobileButtons" class="btn panel__btn trigger">
                        <svg-icon-qouting data="fas fa-ellipsis-h" />
                    </button>
                    <div v-show="showExtraMobileButtons" class="btn-extra-panel">
                        <Button class="panel__btn" :data="item" v-for="item in buttonsFiltered.slice(1)" :key="`button-${item.event}`" @click="$emit(`${item.event}`)" />
                    </div>
                </div>
            </template>
            <div id="panel-btn-teleport-mobile" class="tw-mr-2"></div>

            <Button v-show="!hideButtons" class="panel__btn btn-first" :style="`${buttonsFiltered?.slice(1).length === 1? 'width: 100%': 'width: 90%'}`" :data="item" v-for="item in buttonsFiltered?.slice(0,1)" :key="`button-${item.event}`" @click="$emit(`${item.event}`)" />

        </div>

        <div class="panel__price" v-if="price && showPrice">{{ formatter.formatMoney(price) }}</div>
    </div>
</template>

<script>
import Button from '../components/form/Button.vue'
import { useFormatter } from '../composables/formatter'
import { computed, ref } from 'vue'
import _ from 'lodash'
import { useStore } from 'vuex'

export default ({
    components: {
        Button
    },
    props: ['buttons', 'price','hideButtons', 'isQuoting', 'allData', 'area'],
    setup(props) {
        const formatter  = useFormatter()
        const showExtraMobileButtons = ref(false)
        const store = useStore()

        const showPrice = computed(() => store.getters['auth/showPrice'](props.area))
        
        const buttonsFiltered = computed(() => {
            const btns = _.get(props, 'buttons', [])
            return btns.filter(btn => {
                if(btn.showMe && typeof btn.showMe === 'function') {
                    return btn.showMe({isQuoting: (props.isQuoting || false), allData: props.allData})
                }
                return true
            })
        })

        return {
            formatter, showExtraMobileButtons, buttonsFiltered, showPrice,
        }
    },
})
</script>

<style scoped lang="scss">

.btn-extra-wrapper {
    position: relative;
}

.btn-first {
    flex: 1;
    margin: 0!important;
}
.btn-extra-panel {
    padding: 8px 5px 0px 5px;
    border: 1px solid white;

    button {
        width: 100%;
    }
}

button.trigger {
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    margin: 0!important;
    background: transparent;
    color: white;
    padding: 8px!important;
    border-radius: 2px;
}
.panel {
    height: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--PrimaryColour);
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 37px;
    &__actions {
        display: flex;
        align-items: center;
        width: 100%;
        &.mobile {
            display: none;
            column-gap: 15px;
            .btn-extra-panel {
                position: absolute;
                background: var(--PrimaryColour);
                transform: translateY(-146%);
            }
        }

        &.desktop {
            display: flex;
        }

        @media(max-width: 768px) {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: flex;
                width: auto;
            }
        }

    }
    &__btn {
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0;
        }
        span {
            margin: 0 auto;
            transform: translateX(10px);
        }
    }
    .btn__arrow {
        // margin-left: auto;
        margin-right: 4px;
    }
    &__price {
        color: var(--PrimaryButtonTextColour)
    }
    @media(max-width: 900px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: flex-start;
        height: auto;
        min-height: 53px;
        padding: 10px;
        &__price {
            margin-bottom: 10px;
            margin-left: auto;
        }
    }
    // @media(max-width: 768px) {
    //     // width: calc(100% + 20px);
    //     // position: relative;
    //     // bottom: -10px;
    //     // left: -10px;
    //     position: relative!important;
    //     left: unset;
    //     right: unset;
    //     align-items: center;
    //     &__price {
    //         margin-bottom: 0px;
    //     }
    //     &__actions {
    //         flex-wrap: wrap;
    //         .btn {
    //             margin-bottom: 10px;
    //             &:last-of-type {
    //                 // margin-bottom: 0;
    //             }
    //         }
    //     }
    // }
}
</style>
